import { module } from 'angular'
import 'angular-ui-router'
import 'angular-route'
import trackingAddress from './trackingAddress'
import identityCardViewAddress from './identityCardViewAddress'
import { DialogActions } from '@material-ui/core'


const indexModule = module('index', ['ui.router', 'ngRoute'])

indexModule.config(['$stateProvider', '$locationProvider', function ($stateProvider, $locationProvider) {

    console.log('Configuring...');

    configureRoutes()

    $locationProvider.hashPrefix('');

    function configureRoutes() {
        $stateProvider.state('tag-qrcode-query', {
            url: '/tags/tag-qrcode-query/:tagId',
            onEnter: ['$window', '$stateParams', function ($window, $stateParams) {
                $window.open(`${trackingAddress}/${$stateParams.tagId}`, '_self')

                // $window.open('/tag-query.html/#/tags/tag-qrcode-query/' + $stateParams.tagId,'_self')
            }]
        })

        $stateProvider.state('home', {
            url: '',
            onEnter: ['$window', function ($window) {
                $window.open('login.html#/', '_self')
            }]
        })

        $stateProvider.state('login', {
            url: '/login',
            onEnter: ['$window', '$location', function ($window, $location) {
                const verified = $location.search().verified

                if (verified) {
                    $window.open(`login.html#/?verified=${verified}`, '_self')
                } else {
                    $window.open(`login.html#/`, '_self')
                }

            }]
        })

        $stateProvider.state('passwordResetConfirm', {
            url: '/password-reset-confirm/:accessToken',
            onEnter: ['$window', '$stateParams', function ($window, $stateParams) {
                const accessToken = $stateParams.accessToken
                $window.open(`login.html#/password-reset-confirm/${accessToken}`, '_self')
            }]
        })

        $stateProvider.state('identity-card-view', {
            url: '/packing/identity-card/:identiyCardId',
            onEnter: ['$window', '$stateParams', function ($window, $stateParams) {
                $window.open(`${identityCardViewAddress}/${$stateParams.identiyCardId}`, '_self')

                // $window.open('/tag-query.html/#/tags/tag-qrcode-query/' + $stateParams.tagId,'_self')
            }]
        })

    }
}])

indexModule.run(['$window',function ($window) {

}])

export { indexModule }